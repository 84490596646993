.popup_container {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .4);
    margin: 0;
    position: fixed;
    z-index: 11111;
    display: flex;
    justify-content: center;
    align-items: center;

    >div {
        max-width: 612px;
        height: 200px;
        padding: 22px;
        background-color: #fff;
        border-radius: 15px;
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        >h3 {
            font-size: 30px;
            margin-top: 20px;
        }

        >button {
            background-color: #004b84;
            border: none;
            border-radius: 10px;
            color: #fff;
            font-size: 15px;
            margin-top: 25px;
            padding: 12px 20px;
            margin: 10px auto;
            cursor: pointer;

        }

        >div {
            width: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;


            >button {
                &:first-child {
                    background-color: gray;
                }

                background-color: #004b84;
                border: none;
                border-radius: 10px;
                color: #fff;
                font-size: 15px;
                margin-top: 25px;
                padding: 12px 20px;
                margin: 10px auto;
                cursor: pointer;

            }


        }

        >div:first-child {

            background-color: 'gray'
        }



    }
}