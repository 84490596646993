.device_details_container {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0;
    padding: 0;

    >.device_details_right {
        padding: 37px 62px;
        margin-left: 15%;
        width: 80%;

        >.device_details_header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            >.first {
                display: flex;
                align-items: center;

                >a {
                    text-transform: none;

                    >button {
                        display: flex;
                        padding: 10px;
                        background-color: #004B84;
                        border: none;
                        width: 97px;
                        height: 40px;
                        cursor: pointer;

                        >p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: #FFFFFF;
                            margin-left: 15px;
                        }
                    }
                }

                >h3 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 29px;
                    color: #004B84;
                    margin-left: 38px;
                }
            }

            >.second {
                width: 231px;
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #10BCCE;
                height: 55px;

                >p {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 15px;
                    color: #4A4A4A;
                    text-align: center;
                }
            }
        }

        >.device_details_name {

            >h3 {
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                color: #004B84;
                margin-left: 3px;
                margin-top: 43px;
            }

            >.device_details_list {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin-top: 27px;
                width: 95%;

                >.nameFirst {
                    width: 33%;

                    >h3 {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 29px;
                        color: #004B84;
                        margin-left: 3px;
                        margin-top: 43px;
                        margin-bottom: 25px;
                    }

                    >button {
                        background: #004B84;
                        width: 212px;
                        height: 40px;
                        padding: 5px;
                        margin-left: 5px;
                        margin-top: 41px;
                        border: none;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.5;
                        }

                        >p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            text-align: center;
                            color: #FFFFFF;
                        }
                    }
                }

            }

            >.device_details_lists {
                width: 400px;
                margin-top: 27px;
            }

            >.details_board {
                margin-top: 23px;
                width: 60%;
                background-color: #004B84;
                padding: 10px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                height: 66px;
                padding-right: 200px;

                >p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 29px;

                    color: #FFFFFF;
                }

            }
        }
    }
}


@media screen and (max-width:1050px) {
    .device_details_container {
        >.device_details_right {
            margin-left: 22%;
        }
    }
}

@media screen and (max-width:1500px) {
    .device_details_container {
        >.device_details_right {
            >.device_details_name {
                >.device_details_list {
                    .nameFirst {
                        width: 55%;
                    }
                }
            }
        }
    }
}
