.devList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;


    >p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
    }

    >div {
        width:212px;

        >span {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #10BCCE;
        }
    }

}

.devListSecond {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;


    >p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
    }

    >div {
        width: 154px;

        >span {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
        
        
        }
    }

}

.boardList {
    display: grid;
    grid-template-columns: 221px 191px auto;
    padding-left: 35px;
    margin-bottom: 15px;

    >p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
    }

}