.dev_data {
    width: 100%;
    display: grid;
    grid-template-columns: 170px 160px 100px 135px 290px 150px auto;
    padding-left: 20px;

    >p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        padding: 10px 15px;
        text-align: left;

    }

    >a {
        padding: 10px 15px;
    }
}

@media screen and (max-width:1590px) {
    .dev_data {
        width: 100%;
        margin-left: 0;
        grid-template-columns: 132px 81px 93px 114px 206px 111px auto;
        ;

    }

}



@media screen and (max-width:1090px) {
    .dev_data {
        margin-left: 0px;
        width: 100%;
        padding-right: 40px;

        >p {
            font-size: 12px;
            line-height: 15px;
            padding: 12px 10px;
        }
    }
}