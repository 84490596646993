.container__clientScreen {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0;
    padding: 0;

    >.clients_right {
        padding: 37px 62px;
        margin-left: 15%;
        width: 80%;

        >h3 {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            color: #004B84;
            margin-left: 3px;
        }

        >.clients_inputDiv {
            display: flex;
            width: 50%;
            margin-top: 47px;
            margin-left: 20px;
            align-items: center;

            >p {
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #000000;
            }

            >input {
                width: 44%;
                height: 40px;
                border: 1px solid #10BCCE;
                padding: 10px;
                margin-left: 20px;
            }
        }

        >.clients_board {
            margin-top: 32px;
            width: 80%;
            height: 66px;
            background-color: #004B84;
            padding: 10px;
            display: grid;
            grid-template-columns: 270px 260px 162px auto;
            padding-left: 35px;

            >p {
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                color: #FFFFFF;
                text-align: left;
                align-self: center;
            }
        }

        >.inputs {
            width: 80%;
        }

        >.pagination_div {
            display: flex;
            align-items: center;
            margin-top: 20px;
            margin-left: 34px;

            >.pagination_div_item {
                background-color: white;
                width: 40px;
                height: 40px;
                padding: 5px;
                color: #000000;
                border: none;
                text-align: center;
                display: flex;
                align-items: center;
                list-style-type: none;
                justify-content: center;
                cursor: pointer;

            }

            >.active {
                width: 40px;
                height: 40px;
                padding: 5px;
                border: none;
                text-align: center;
                display: flex;
                align-items: center;
                list-style-type: none;
                justify-content: center;
                background-color: #004B84;
                color: #FFFFFF;
                cursor: pointer;
                

            }

            >.pagination_div_item_link {
                // display: none;
                list-style-type: none;
            }
        }
    }
}



@media screen and (max-width:1500px) {
    .container__clientScreen>.clients_right>.clients_board {
        width: 100%;
    }

}