.container {
    width: 100%;
    height: 98vh;
    background-color: white;
    display: flex;
    justify-content: center;
    position: relative;

    >.container_login {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        width: 255px;
        top: 15%;

        >p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            color: #004B84;
            margin-top: 137px;
            margin-bottom: 28px;
        }

        >img {
            width: 155px;
            height: 52px;
        }

        >.container_inputDiv {
            width: 100%;

            >div {
                display: flex;
                justify-content: space-between;

                >input {
                    border: 1px solid #10BCCE;
                    width: 75%;
                    height: 40px;
                    padding: 10px;
                }

                ::placeholder {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: #D3D3D3;
                }



                >button {
                    width: 40px;
                    height: 40px;
                    background-color: #004B84;
                    border: none;
                    cursor: pointer;

                    >img {
                        margin-top: 5px;
                        margin-left: 2px;
                    }

                    &:hover {
                        opacity: 0.5;
                    }
                }


            }

            >input {
                border: 1px solid #10BCCE;
                width: 100%;
                height: 40px;
                padding: 10px;
                margin-bottom: 15px;
            }

            >p {
                margin-bottom: 15px;
                margin-top: 0;
                color: red;
                font-size: 12px;
                line-height: 10px;

            }

            ::placeholder {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #D3D3D3;
            }

            >a {
                >button {
                    width: 40px;
                    height: 40px;
                    background-color: #004B84;
                    border: none;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.5;
                    }
                }
            }


        }

    }

    >.image {
        width: 101px;
        height: 13px;
        position: absolute;
        bottom: 5%;
    }

    >.container_register {
        display: none;

        position: absolute;
        top: 15%;

        >img {
            width: 155px;
            height: 52px;
            margin-bottom: 200px;
        }

        >.dropdownBlock {
            width: 245px;
            height: 40px;
            background-color: #004B84;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px;
            margin-bottom: 17px;
        }

        >.input_div {
            width: 100%;

            >input {
                width: 100%;
                padding: 10px;
                height: 40px;
                border: 1px solid #10BCCE;
            }

            ::placeholder {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #D3D3D3;
            }

            >.input_div_password {
                margin-top: 20px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                >input {
                    border: 1px solid #10BCCE;
                    width: 75%;
                    height: 40px;
                    padding: 10px;
                }

                ::placeholder {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: #D3D3D3;
                }

                >a {
                    >button {
                        width: 40px;
                        height: 40.5px;
                        background-color: #004B84;
                        border: none;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.5;
                        }
                    }
                }
            }
        }

        >.link_div {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;

            >a {
                text-decoration: none;

                >p {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: #004B84;
                }
            }

            >.link_div_line {
                width: 1px;
                height: 15px;
                background-color: #BDBDBD;
            }
        }

    }
}