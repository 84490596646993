.inputDiv {
    width: 100%;
    display: grid;
    grid-template-columns: 30% 40% 30%;
    margin-top: 12px;
    margin-left: 20px;

    >p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        text-transform: capitalize;
    }

    >input {
        width: 80%;
        height: 40px;
        border: 1px solid #10BCCE;
        padding: 10px;
        // margin-left: 155px;
    }

    >span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #BDBDBD;
        // margin-left: 35px;
    }
}