.container_clientDetails {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0;
    padding: 0;

    >.clientDetails_right {
        padding: 37px 62px;
        margin-left: 15%;
        width: 80%;

        >.client_details_header {
            display: flex;
            align-items: center;

            >a {
                text-transform: none;

                >button {
                    display: flex;
                    padding: 10px;
                    background-color: #004B84;
                    border: none;
                    width: 97px;
                    height: 40px;
                    cursor: pointer;

                    >p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: #FFFFFF;
                        margin-left: 15px;
                    }
                }
            }

            >h3 {
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                color: #004B84;
                margin-left: 38px;
            }
        }

        >.clients_details_name {
            >h3 {
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                color: #004B84;
                margin-left: 3px;
                margin-top: 43px;
            }

            >.clents_details_list {
                width: 400px;
                margin-top: 27px;
            }

            >.clients_details_buttonsDiv {
                width: 410px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 77px;

                >button {
                    background: #004B84;
                    width: 212px;
                    height: 40px;
                    padding: 5px;
                    margin-left: 5px;
                    margin-top: 41px;
                    border: none;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.5;
                    }

                    >p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        text-align: center;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1500px) {
    .container_clientDetails {
        .clientDetails_right {
            width: 85%;
        }
    }

}