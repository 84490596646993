.navbarDiv {
    width: 15%;
    height: 100vh;
    position: fixed;
    background-color: #004B84;
    padding: 30px 10px 30px 23px;


    >img {
        width: 155px;
        height: 52px;
    }

    >.navbarDiv_input {
        width: 199px;
        height: 50px;
        border: 1px solid white;
        margin-top: 28px;
        margin-left: 9px;
        padding: 15px;

        >p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 15px;
            color: #FFFFFF;
        }
    }

    >.container_linkDiv {
        margin-top: 40px;
        margin-left: 9px;

        >ul>li {
            margin: 18px 0;
            list-style-type: none;
            transition: background-color .2s;

            >a {
                text-decoration: none;
                padding: 18px 0;
                display: block;

                >p {
                    // font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    color: #FFFFFF;
                    margin-left: 15px;
                }
            }

            &.active {
                background-color: #016bbb;
                >a {
                    >p {
                    }
                }
            }
            &:hover {
                background-color: #486a85;
            }
        }
    }

    .navbar_logo {
        position: absolute;
        bottom: 20px
    }

}

@media screen and (max-width:1600px) {
    .navbarDiv {
        width: 17%;
    }
}

@media screen and (max-width:1500px) {
    .navbarDiv {
        width: 17%;

        >.navbarDiv_input {
            width: 167px;

        }

        >.container_linkDiv {
            margin-top: 30px;

            >ul>li {

                >a {
                    >p {
                        line-height: 15px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1200px) {
    .navbarDiv {
        width: 21%;
    }
}

@media screen and (max-width:1024px) {
    .navbarDiv {
        width: 23.5%;
    }
}

@media screen and (max-width:790px) {
    .navbarDiv {
        padding: 30px 10px 30px 13px;
        width: 25%;

        >.navbarDiv_input {
            width: 153px;

        }
    }
}
