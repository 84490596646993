.auth_list {
    width: 70%;
    margin-top: 34px;
    margin-bottom: 56px;

    >.auth_list_header {
        width: 100%;
        height: 66px;
        background-color: #004B84;
        padding: 10px;
        display: grid;
        grid-template-columns: 129px 245px 220px 150px 150px auto !important;
        padding-left: 35px;
        margin-bottom: 15px;

        >p {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            color: #FFFFFF;
            text-align: left;
            align-self: center;
        }
    }
}

.list {
    display: grid;
    grid-template-columns: 129px 245px 220px 150px 150px auto !important;
    padding-left: 20px;
    width: 100%;


    >p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        padding: 10px 15px;
        text-align: left;

    }
}


@media screen and (max-width:1500px) {
    .auth_list{
        width: 100%;
        .auth_list_header{
            grid-template-columns: 129px 245px 220px 150px 150px auto !important;
        }
    }
    
}