.addNew_admins {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0;
    padding: 0;

    >.container_addnew_rightPart {
        padding: 37px 62px;
        margin-left: 15%;
        width: 80%;

        >h3 {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            color: #004B84;
            margin-left: 3px;
        }

        >.inputDiv {
            width: 30%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 25px;

            >p {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #000000;
            }

            >input {
                background: #FFFFFF;
                border: 1px solid #10BCCE;
                width: 245px;
                height: 40px;
            }

            ::placeholder {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #D3D3D3;
            }

            >.container {
                display: block;
                position: relative;
                cursor: pointer;
                font-size: 22px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                height: 25px;
                width: 25px;
                margin-right: 220px;
            }


            .container input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 25px;
                width: 25px;
                background-color: white;
                border: 1px solid #10BCCE;
            }

            .container:hover input~.checkmark {
                background-color: #ccc;
            }

            .container input:checked~.checkmark {
                background-color: #10BCCE;
            }


            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }


            .container input:checked~.checkmark:after {
                display: block;
            }


            .container .checkmark:after {
                left: 8px;
                top: 3.5px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }

        >.buttons_div {
            width: 48%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            >button {
                cursor: pointer;
                width: 40%;
                height: 40px;
                background-color: #004B84;
                border: none;
                margin-top: 30px;

                &:hover {
                    opacity: 0.5;
                }

                >p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                    color: #FFFFFF;
                }
            }
        }

    }
}