.pilot_lists {
    width: 60%;
    margin-top: 34px;
    margin-bottom: 56px;

    >.pilot_lists_header {
        width: 100%;
        height: 66px;
        background-color: #004B84;
        padding: 10px;
        display: grid;
        grid-template-columns:  80px 180px 180px 150px auto;
        padding-left: 35px;
        margin-bottom: 15px;

        >p {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            color: #FFFFFF;
            text-align: left;
            align-self: center;
        }
    }

}


.testList {
    display: grid;
    grid-template-columns: 80px 180px 180px 150px auto;
    padding-left: 35px;
    margin-bottom: 15px;

    >p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
    }

}