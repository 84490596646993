.container_dashboard {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0;
    padding: 0;


    >.dashboard_right {
        padding: 37px 67px;
        margin-left: 17%;
        width: 50%;


        >h3 {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            color: #004B84;
        }

        >.dashboard_right_divs {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 14px;


        }

        >.marginDiv {
            margin-top: 54px;

            >h3 {
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                color: #004B84;
            }

            >.dashboard_right_divs {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-top: 14px;
            }

        }
    }
}


@media screen and (max-width:1600px) {
    .container_dashboard {
        >.dashboard_right {
            width: 70%;
            padding: 37px 50px;
        }
    }
}

@media screen and (max-width:1150px) {
    .container_dashboard {
        >.dashboard_right {
            width: 56%;
            padding: 37px 2px;
            margin-left: 25%;
        }
    }
}