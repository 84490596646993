.dashboard_square {
    width: 200px;
    height: 200px;
    background-color: #004B84;
    margin-left: 10px;

    &:hover {
        opacity: 0.5;
    }

    >h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 60px;
        line-height: 73px;
        color: #FFFFFF;
        text-align: center;
        margin-top: 55px;
    }

    >p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        text-align: center;
        margin-top: 30px;
    }
}