.settings_container {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0;
    padding: 0;

    >.settings_right {
        padding: 37px 62px;
        margin-left: 15%;
        width: 80%;

        >h3 {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            color: #004B84;
            margin-left: 3px;
        }

        >.settings_input {
            margin-top: 55px;
            width: 70%;
        }

        >.buttonsDiv {
            display: flex;
            // align-items: center;
            justify-content: space-between;
            width: 50%;

            >button {
                width: 326px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #004B84;
                margin-left: 20px;
                margin-top: 45px;
                cursor: pointer;
                border: none;
                align-self: center;

                >p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                    color: #FFFFFF;
                }

                &:hover {
                    opacity: 0.8;
                }
            }

            >p {
                margin-top: 55px;
                color: red;
            }
        }


    }
}