.container_devices {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0;
    padding: 0;

    >.devices_right {
        padding: 37px 62px;
        margin-left: 15%;
        width: 80%;

        >h3 {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            color: #004B84;
            margin-left: 3px;
        }

        >.devices_inputDiv {
            display: flex;
            justify-content: space-between;
            width: 80%;
            margin-top: 47px;
            margin-left: 20px;
            align-items: center;

            >p {
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #000000;
            }

            >input {
                width: 20%;
                height: 40px;
                border: 1px solid #10BCCE;
                padding: 10px;
            }
        }

        >.devices_board {
            margin-top: 32px;
            width: 90%;
            height: 66px;
            background-color: #004B84;
            padding: 10px;
            display: grid;
            grid-template-columns: 170px 160px 100px 130px 290px 150px auto;
            padding-left: 35px;

            >p {
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                color: #FFFFFF;
                text-align: left;
                align-self: center;
            }
        }

        >.pagination_div {
            display: flex;
            align-items: center;
            margin-top: 20px;
            margin-left: 34px;

            >.pagination_div_item {
                background-color: white;
                width: 40px;
                height: 40px;
                padding: 5px;
                color: #000000;
                border: none;
                text-align: center;
                display: flex;
                align-items: center;
                list-style-type: none;
                justify-content: center;
                cursor: pointer;

            }

            >.active {
                width: 40px;
                height: 40px;
                padding: 5px;
                border: none;
                text-align: center;
                display: flex;
                align-items: center;
                list-style-type: none;
                justify-content: center;
                background-color: #004B84;
                color: #FFFFFF;
                cursor: pointer;
            }

            >.pagination_div_item_link {
                list-style-type: none;
                cursor: pointer;
            }
        }

    }

}


@media screen and (max-width:1580px) {
    .container_devices {
        >.devices_right {

            width: 88%;

            >.devices_inputDiv {
                width: 85%;
            }

            >.devices_board {
                width: 100%;
                grid-template-columns: 132px 81px 93px 114px 206px 111px auto;


                >p {
                    font-size: 19px;
                    line-height: 10px;
                }
            }
        }
    }
}

@media screen and (max-width:1100px) {
    .container_devices {
        >.devices_right {
            width: 100%;
        }
    }
}


@media screen and (max-width:1090px) {
    .container_devices {
        >.devices_right {
            width: 80%;
            margin-left: 23%;
            padding: 20px 30px;

            >.devices_inputDiv {
                width: 85%;
            }

            >.devices_board {
                width: 100%;
                padding-right: 0;

                >p {
                    font-size: 19px;
                    line-height: 10px;
                }
            }
        }
    }
}