.container_admins {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0;
    padding: 0;

    >.container_admins_rightPart {
        padding: 37px 62px;
        margin-left: 15%;
        width: 80%;

        >h3 {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            color: #004B84;
            margin-left: 3px;
        }

        >a {
            >button {
                background-color: #004B84;
                width: 180px;
                height: 49px;
                cursor: pointer;
                border: none;

                >p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                    color: #FFFFFF;
                }

                &:hover {
                    opacity: 0.5;
                }
            }
        }

    }
}