.client_data{
    width: 100%;
    display: grid;
    grid-template-columns:270px 260px 162px auto;
    padding-left: 20px;

    >p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        padding: 10px 15px;
        text-align: left;

    }

    >a {
        padding: 10px 15px;
    }
}